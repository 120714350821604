<template>
  <button
    class="relative flex flex-row flex-nowrap justify-center items-center menu-item mb-3px p-12px items-center rounded-12px cursor-pointer common-button overflow-hidden font-500 text-14px select-none"
    :class="{
      'common-button-active': props.isActive ?? true,
      'common-button-disabled': disabled ?? false,
      'common-button-grey': disabled ?? false,
    }"
    :="{ ...props.buttonProps }"
    @click="
      props.activateLoader
        ? onClickWithLoader($event)
        : onClickWithoutLoader($event)
    "
  >
    <div
      v-if="!!props.svgIconName"
      class="flex common-button-icon"
      :class="{ 'mr-8px': !!props.isLabelOpen }"
    >
      <CommonAsset
        :name="props.svgIconName"
        class="sliderbar-icon max-w-none"
        :class="extraSvgClass"
      />
    </div>
    <div v-show="props.isLabelOpen ?? true" class="flex items-center">
      <slot />
    </div>

    <div
      v-show="isRecommendedStatus && (props.isLabelOpen ?? true)"
      class="w-full"
    >
      <div class="flex justify-between w-full items-center">
        <slot name="status" />
      </div>
    </div>

    <transition name="el-fade-in-linear">
      <div
        v-show="props.loading ?? isLoaderMaskShow"
        class="loader-mask absolute top-0 left-0 bottom-0 right-0 inline-flex items-center justify-center blur-1"
      >
        <LazyCommonSpinner :size="20" />
      </div>
    </transition>
  </button>
</template>

<script setup lang="ts">
import type { ButtonHTMLAttributes } from "vue"

const props = defineProps<{
  isActive?: Boolean
  svgIconName?: string
  isLabelOpen?: Boolean
  buttonProps?: Omit<ButtonHTMLAttributes, "onClick">
  onClick?: ButtonHTMLAttributes["onClick"]
  activateLoader?: boolean
  loading?: Boolean
  disabled?: boolean
  isRecommendedStatus?: boolean
}>()
const isLoaderMaskShow = ref(false)

const onClickWithLoader: ButtonHTMLAttributes["onClick"] = async (event) => {
  const target = event.target as HTMLButtonElement
  target.disabled = true
  isLoaderMaskShow.value = true
  try {
    if (props.onClick) await props.onClick(event)
    // eslint-disable-next-line no-useless-catch
  } catch (error) {
    throw error
  } finally {
    target.disabled = false
    isLoaderMaskShow.value = false
  }
}
const onClickWithoutLoader: ButtonHTMLAttributes["onClick"] = async (event) => {
  const target = event.target as HTMLButtonElement
  target.disabled = true
  if (props.onClick) await props.onClick(event)
  target.disabled = false
}

const extraSvgClass = computed(() => {
  switch (props.svgIconName) {
    case "icons-sliderbar-gift-yellow":
      return "promotion-extra-icon"
    default:
      return ""
  }
})

// Event listener
</script>

<style lang="scss" scoped>
@import "~/assets/sass/mixins.scss";
@mixin active {
  box-shadow: var(--v-navbar-active-shadow);
  color: var(--v-navbar-active-color);
  border-radius: 12px;
  @include background-border(
    var(--v-navbar-active-bg),
    linear-gradient(180deg, #b8ff85 0%, rgba(0, 82, 255, 0) 100%),
    1px
  );

  .common-button-icon {
    // animation-name: upAnimation;
    transform-origin: center bottom;
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    .sliderbar-icon {
      opacity: 1;
    }
  }
}
.common-button {
  background: var(--v-navbar-bg);
  //box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: var(--v-navbar-color);
  transition: box-shadow 0.5s;
  border-radius: 12px;
  border: transparent 1px solid;
  .sliderbar-icon {
    opacity: 0.6;
  }
  //@include active;
  &:hover {
    @include active;
  }
  &:hover .common-button-icon {
    animation: none;
  }

  &-active {
    @include active;
  }
  &-disabled {
    pointer-events: none;
    //cursor: ;
    background: #1c1c2d !important;
    color: #737373 !important;
  }
  &-grey {
    @include background-border(
      linear-gradient(#324353 0%, #233546 100%),
      linear-gradient(180deg, #475a6c 0%, #0e1c28 100%),
      1px
    );
  }
  .loader-mask {
    transition: all 300ms;
    background: rgba(0, 0, 0, 0.457);
  }
}

.promotion-extra-icon {
  transform: scale(1.5) !important;
  width: 15.2px !important;
  opacity: 1 !important;
}
</style>
